<template>
  <div class="item-content">
    <div class="trip-title" @click="$emit('titleClick')">
      <p>{{ title }}</p>
      <v-icon class="material-icons arrow-icon">{{isSelected ? "keyboard_arrow_down" : "keyboard_arrow_right"}}</v-icon>
    </div>
    <div v-if="isSelected" class="trip-items">
      <div class="trip-item" @click="$emit('detailsClick')">
        <v-icon class="material-icons trip-item-icon">border_color</v-icon>
        <p>{{ $i18n.t("details") }}</p>
      </div>
      <div class="trip-item" @click="$emit('mapClick')">
        <v-icon class="material-icons trip-item-icon">map</v-icon>
        <p>{{ $i18n.t("viewOnMap") }}</p>
      </div>
      <div class="trip-item" @click="$emit('chatClick')">
        <v-icon class="material-icons trip-item-icon">chat</v-icon>
        <p>{{ $i18n.t("chat") }}</p>
      </div>
      <div class="trip-item" @click="$emit('expensesClick')">
        <v-icon class="material-icons trip-item-icon">ballot</v-icon>
        <p>{{ $i18n.t("expenses") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TripItem",
  props: {
    title: {
      type: String,
      required: true
    },
    path: String,
    onClick: Function,
    isSelected: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.trip-title {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  padding: 0.1em;
  padding-left: 1em;
  padding-right: 1em;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.arrow-icon {
  font-size: 1.5em;
}

.trip-item {
  cursor: pointer;
  padding: 0.1em;
  padding-left: 2em;
  transition: background-color 0.3s ease;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.trip-item-icon {
  font-size: 1.5em;
  color: var(--primary)
}

.trip-title:hover {
  background-color: var(--primary-lower);
}

.trip-item:hover {
  background-color: var(--primary-lower);
}
</style>
