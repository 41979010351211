<template>
  <div class="coming-soon-container">
    <img alt="Kowander logo" src="../assets/logo.png" class="logo" />
    <h1>Coming soon...</h1>
  </div>
</template>

<script>
export default {
  name: "ComingSoon"
};
</script>

<style scoped>
.coming-soon-container {
  height: 100dvh;
  width: 100%;
  text-align: center;
}

.logo {
  margin: 0;
  margin-top: 10px;
  max-height: 200px;
}

h1 {
  font-size: 2rem;
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;
}
</style>
