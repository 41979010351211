<template>
  <div class="modal-background" v-if="isVisible" @click="closeModal()">
    <div class="modal-container" @click.stop>
      <form @submit.prevent="saveChanges">
        <div class="modal-header">
          <p @click="closeModal" class="modal-button">Cancel</p>
          <h2>{{ $i18n.t("location") }}</h2>
          <button type="submit" class="modal-button save-button">Save</button>
        </div>
        <div class="modal-content">
          <div class="form-group">
            <label>{{ $i18n.t("location") }}</label>
            <input
              type="text"
              :placeholder="$i18n.t('search')"
              v-model="location.name"
              :required="true"
              @input="fetchLocationSuggestions"
            />
            <ul v-if="suggestions.length" class="suggestions-list">
              <li
                v-for="(suggestion, index) in suggestions"
                :key="index"
                @click="selectSuggestion(suggestion)"
              >
                {{ suggestion.description }}
              </li>
            </ul>
          </div>
          <!-- Date -->
          <div class="form-group">
            <label>{{ $i18n.t("date") }}</label>
            <input
              :required="true"
              type="datetime-local"
              v-model="formattedDate"
            />
          </div>
          <!-- Details -->
          <div class="form-group">
            <label>{{ $i18n.t("details") }}</label>
            <textarea v-model="location.description"></textarea>
          </div>
          <!-- Map -->
          <div class="map-container">
            <GoogleMap
              :api-key="googleMapAPIKey"
              style="width: 100%; height: 50dvh"
              :center="center"
              :zoom="15"
              @click="updateMarkerLocation"
            >
              <Marker
                v-if="location"
                :options="{
                  position: { lat: location.latitude, lng: location.longitude },
                  title: location.name
                }"
              />
            </GoogleMap>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { GoogleMap, Marker } from "vue3-google-map";
export default {
  name: "LocationModal",
  components: { GoogleMap, Marker },
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    initialLocation: {
      type: Object,
      default: undefined,
      required: false
    },
    initialDate: {
      type: Date,
      default: undefined,
      required: false
    }
  },
  data() {
    return {
      location: { ...this.initialLocation },
      googleMapAPIKey: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
      suggestions: [],
      autocompleteService: null
    };
  },
  computed: {
    center() {
      if (this.location.latitude && this.location.longitude) {
        return { lat: this.location.latitude, lng: this.location.longitude };
      } else {
        return { lat: 52.3676, lng: 4.9041 };
      }
    },
    formattedDate: {
      get() {
        return this.formatDate(this.location.startDate);
      },
      set(newDate) {
        this.location.startDate = new Date(newDate).toISOString();
      }
    }
  },
  created() {
    this.initGoogleAPI();
    if (!this.location.startDate) {
      this.location.startDate = this.initialDate;
    }
  },
  methods: {
    closeModal() {
      const confirmed = window.confirm(
        this.$i18n.t("confirm-discard-location-changes")
      );
      if (confirmed) {
        this.$emit("close");
      }
    },
    saveChanges() {
      if (
        this.location.name &&
        this.location.startDate &&
        this.location.longitude &&
        this.location.latitude
      ) {
        this.$emit("save", this.location);
      } else {
        alert(this.$i18n.t("please-fill-out-the-fields"));
      }
    },
    formatDate(date) {
      if (!date) return "";
      const d = new Date(date);
      const pad = (n) => (n < 10 ? "0" + n : n);
      const yyyy = d.getFullYear();
      const MM = pad(d.getMonth() + 1); // Months are 0-based
      const dd = pad(d.getDate());
      const hh = pad(d.getHours());
      const mm = pad(d.getMinutes());
      return `${yyyy}-${MM}-${dd}T${hh}:${mm}`;
    },
    updateMarkerLocation(event) {
      this.location.latitude = event.latLng.lat();
      this.location.longitude = event.latLng.lng();
    },
    fetchLocationSuggestions() {
      if (this.location.name.length > 2) {
        this.autocompleteService?.getPlacePredictions(
          { input: this.location.name },
          (predictions, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              this.suggestions = predictions;
            }
          }
        );
      }
    },
    selectSuggestion(suggestion) {
      this.location.name = suggestion.description;
      this.suggestions = [];

      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ placeId: suggestion.place_id }, (results, status) => {
        if (status === "OK" && results[0]) {
          const location = results[0].geometry.location;
          this.location.latitude = location.lat();
          this.location.longitude = location.lng();
        }
      });
    },
    initGoogleAPI() {
      if (window.google && window.google.maps) {
        this.autocompleteService =
          new window.google.maps.places.AutocompleteService();
      }
    }
  },
  metaInfo() {
    return {
      script: [
        {
          src:
            "https://maps.googleapis.com/maps/api/js?key=" +
            process.env.VUE_APP_GOOGLE_MAP_API_KEY +
            "&libraries=places",
          async: false,
          defer: false,
          callback: this.initGoogleAPI
        }
      ]
    };
  }
};
</script>

<style scoped>
.suggestions-list {
  list-style: none;
  padding: 0;
  margin-top: 5px;
  background-color: var(--background-secondary);
  position: absolute;
  width: 100%;
  z-index: 1000;
}

.suggestions-list li {
  padding: 10px;
  cursor: pointer;
}

.suggestions-list li:hover {
  background-color: var(--primary-lower);
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  overflow: auto;
  z-index: 1000;
}

.modal-container {
  background-color: var(--background-secondary);
  border-radius: 10px;
  width: 90%;
  max-width: 90em;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  margin: auto;
  margin-top: auto;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2em;
  padding-left: 1em;
  padding-right: 1em;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}
.modal-content {
  padding-left: 2em;
  padding-right: 2em;
}

.modal-button {
  background: none;
  border: none;
  font-size: 1em;
  cursor: pointer;
}

.save-button {
  color: var(--primary);
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}
.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  font-size: 14px;
}

.map-container {
  margin-top: 20px;
}
</style>
