<template>
  <div class="home-container">
    <div class="navigation-bar">
      <v-icon class="material-icons add-icon" @click="showTripModal = true"
        >add</v-icon
      >
    </div>
    <PulseLoader v-if="loading" size="1em" class="loader" />
    <TripItem
      v-for="item in trips"
      :isSelected="item._id === $route.params.id"
      :key="item._id"
      :title="item.name"
      @titleClick="selectTrip(item)"
      @detailsClick="selectDetails(item)"
      @mapClick="selectMap(item)"
      @chatClick="selectChat(item)"
      @expensesClick="selectExpenses(item)"
    />
    <TripModal
      v-if="showTripModal"
      :isVisible="showTripModal"
      :initialTrip="trip"
      @close="closeTripModal"
      @save="handleTripSave"
    />
  </div>
</template>

<script>
import router from "@/router";
import TripItem from "@/views/components/TripItem/TripItem.vue";
import TripModal from "./TripModal.vue";
import TripsRepository from "@/repositories/TripsRepository";
export default {
  name: "HomeScreen",
  components: { TripItem, TripModal },
  data() {
    return {
      showTripModal: false,
      trips: undefined,
      trip: { locations: [], users: [] },
      loading: true
    };
  },
  methods: {
    async selectTrip(item) {
      if (this.$route.params.id == item._id) {
        router.replace("/home");
      } else {
        router.replace("/home/" + item._id);
      }
    },
    async selectDetails(item) {
      router.push("/details/" + item._id);
    },
    async selectMap(item) {
      console.log(item);
    },
    async selectChat(item) {
      console.log(item);
    },
    async selectExpenses(item) {
      console.log(item);
    },
    closeTripModal() {
      this.showTripModal = false;
    },
    async handleTripSave(trip) {
      const addedTrip = await TripsRepository.addTrip(trip);
      this.trips = addedTrip;
      this.closeTripModal();
    },
    async fetchTrips() {
      this.loading = true;
      try {
        this.trips = await TripsRepository.fetchTrips();
      } catch (error) {
        alert(error);
      }
      this.loading = false;
    }
  },
  async created() {
    await this.fetchTrips();
  }
};
</script>

<style scoped>
.loader {
    margin: auto
}
.home-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.navigation-bar {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  padding: 0.4em;
  display: flex;
  justify-content: flex-end;
}
.add-icon {
  padding: 0.2em;
  cursor: pointer;
}
</style>
