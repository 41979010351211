<template>
  <div class="user-profile-container">
    <div class="navigation-bar">
      <div class="back-container" @click="goBack">
        <v-icon class="material-icons arrow-icon">keyboard_arrow_left</v-icon>
        <p class="title">{{ this.user?.displayName }}</p>
      </div>
    </div>
    <pulse-loader v-if="loading" size="1em" class="loader" />
    <div class="header" v-if="!loading">
      <qrcode-vue
        v-if="user"
        :value="user.qrCode"
        level="H"
        render-as="svg"
        class="qr-code"
      />
      <p class="email">{{ user.email }}</p>
    </div>
    <div class="buttons-container" v-if="!loading">
      <div class="button" @click="notify(user)">
        <p class="button-title">{{ $i18n.t("ping") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationRepository from "@/repositories/NotificationRepository";
import UserRepository from "@/repositories/UserRepository";
import router from "@/router";
import QrcodeVue from "qrcode.vue";

export default {
  name: "DetailsScreen",
  components: { QrcodeVue },
  props: {
    userID: String
  },
  data() {
    return {
      user: undefined,
      loading: true
    };
  },
  methods: {
    goBack() {
      router.back();
    },
    notify(user) {
      NotificationRepository.notify(user);
    }
  },
  async created() {
    if (this.$route.params.id) {
      this.loading = true
      this.user = await UserRepository.fetchUser(this.$route.params.id);
      this.loading = false
    }
  }
};
</script>

<style scoped>
.user-profile-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.navigation-bar {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  flex: 0;
  padding: 0.4em;
}
.back-container {
  display: flex;
  align-items: center;
  gap: 0.5em;
  cursor: pointer;
  width: fit-content;
}
.arrow-icon {
  font-size: 2em;
}
.title {
  margin: 0;
  font-size: 1.3em;
}
.loader {
  margin: auto;
}
.header {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.qr-code {
  margin-top: 1em;
  width: 10em;
  height: 10em;
}
.email {
  text-align: center;
}
.buttons-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.button {
  background-color: var(--background-secondary);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-radius: 1em;
  cursor: pointer;
}
.button:hover {
  background-color: var(--primary-lower);
}
.button-title {
  margin: 0;
}
</style>
