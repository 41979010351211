<template>
  <div class="tab-bar">
    <div class="logo-container">
      <img
        alt="Kowander logo"
        src="../../../assets/logo-with-no-text.png"
        class="logo"
      />
      <p class="title">Kowander</p>
    </div>

    <div class="list">
      <TabItem
        v-for="(item, index) in items"
        :isSelected="$route.path.startsWith(item.path)"
        :key="index"
        :title="item.title"
        :path="item.path"
        @click="item.onClick(item)"
      />
    </div>
  </div>
</template>

<script>
import TabItem from "./TabItem.vue";

export default {
  name: "TabBar",
  components: {
    TabItem
  },
  props: {
    items: Array
  },
  data() {
    return {};
  },
  methods: {},
  created() {}
};
</script>

<style scoped>
.tab-bar {
  background-color: var(--primary-lower);
  overflow: hidden;
  width: fit-content;
  height: 100dvh;
  align-items: center;
}

.logo-container {
  display: flex;
  align-items: center;
  margin-top: 1em;
  margin-left: 1em;
  margin-right: 1em;
  gap: 1em;
}

.logo {
  height: 1.5em;
  margin: 0;
}

.title {
  font-family: "BukhariScript", sans-serif;
  font-size: 1em;
  color: var(--primary);
}

.list {
  margin-top: 1em;
}
</style>
