<template>
  <div class="details-container">
    <div class="navigation-bar">
      <div class="back-container" @click="goBack">
        <v-icon class="material-icons arrow-icon">keyboard_arrow_left</v-icon>
        <p class="title">{{ this.trip?.name }}</p>
      </div>
      <div class="action-icons-container">
        <v-icon class="material-icons delete-icon" @click="deleteClicked"
          >delete</v-icon
        >
        <v-icon class="material-icons edit-icon" @click="editClicked"
          >edit</v-icon
        >
      </div>
    </div>
    <div v-if="trip">
      <p class="section-title">{{ $i18n.t("details") }}</p>
      <div class="items-container">
        <div class="item">
          <p class="item-title">{{ $i18n.t("date") }}</p>
          <p class="item-value">
            {{ $i18n.t("from") }} {{ formatDate(trip.startDate) }}
            {{ $i18n.t("to") }}
            {{ formatDate(trip.endDate) }}
          </p>
        </div>
      </div>
      <p class="section-title">{{ $i18n.t("travelers") }}</p>
      <div class="items-container">
        <div
          class="item"
          v-for="(user, index) in travelers"
          :class="isLoggedInUser(user) ? 'disabled' : 'clickable'"
          :key="index"
          @click="userClicked(user)"
        >
          <div
            class="item-title-container"
            :class="
              index !== travelers.length - (isOwner ? 0 : 1) ? 'separator' : ''
            "
          >
            <p class="item-title">
              {{ user.displayName }}
              {{
                trip.owners.some((use) => {
                  return user._id === use._id;
                })
                  ? $i18n.t("admin")
                  : ""
              }}
            </p>

            <div
              class="users-actions-container"
              @click.stop
              v-if="!isLoggedInUser(user)"
            >
              <v-icon
                v-if="!isUserOwner(user)"
                class="material-icons delete-user-icon"
                @click="deleteUserClicked(user)"
                >delete</v-icon
              >
              <p
                v-if="!isUserOwner(user)"
                class="promote-user-icon"
                @click="promoteUserClicked(user)"
              >
                {{ $i18n.t("promote") }}
              </p>
              <p
                v-if="isUserOwner(user)"
                class="demote-user-icon"
                @click="demoteUserClicked(user)"
              >
                {{ $i18n.t("demote") }}
              </p>
            </div>
          </div>
        </div>
        <div class="item clickable" v-if="isOwner">
          <p class="item-title button">{{ $i18n.t("add-traveler") }}</p>
        </div>
      </div>
      <p class="section-title">{{ $i18n.t("locations") }}</p>
      <div class="items-container">
        <div
          class="item clickable"
          :class="
            index !== trip.locations.length - (isOwner ? 0 : 1)
              ? 'separator'
              : ''
          "
          v-for="(location, index) in trip.locations"
          :key="index"
          @click="locationClicked(location)"
        >
          <p class="item-title">
            {{ location.name }}
          </p>
          <div class="location-actions-container" @click.stop>
            <v-icon
              class="material-icons delete-location-icon"
              @click="deleteLocationClicked(location)"
              >delete</v-icon
            >
          </div>
        </div>
        <div class="item clickable" v-if="isOwner">
          <p class="item-title button" @click="addLocationClicked">
            {{ $i18n.t("add-location") }}
          </p>
        </div>
      </div>
    </div>
    <LocationModal
      v-if="showLocationModal"
      :isVisible="showLocationModal"
      :initialLocation="location"
      :initialDate="
        trip.locations[trip.locations.length - 1]?.startDate ?? trip?.startDate
      "
      @close="closeLocationModal"
      @save="handleLocationSave"
    />
    <TripModal
      v-if="showTripModal"
      :isVisible="showTripModal"
      :initialTrip="trip"
      @close="closeTripModal"
      @save="handleTripSave"
    />
    <pulse-loader v-if="!trip" size="1em" class="loader" />
  </div>
</template>

<script>
import TripsRepository from "@/repositories/TripsRepository";
import router from "@/router";
import LocationModal from "@/views/LocationModal.vue";
import TripModal from "./TripModal.vue";

export default {
  name: "DetailsScreen",
  components: { LocationModal, TripModal },
  data() {
    return {
      trip: undefined,
      location: undefined,
      showLocationModal: false,
      showTripModal: false
    };
  },
  computed: {
    isOwner() {
      const user = JSON.parse(localStorage.getItem("loggedInUser"));
      return this.trip.owners.some((owner) => {
        return owner?._id === user?._id;
      });
    },
    travelers() {
      return [...this.trip.users, ...this.trip.owners].sort((a, b) => {
        if (a.displayName.toLowerCase() < b.displayName.toLowerCase())
          return -1;
        if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) return 1;
        return 0;
      });
    }
  },
  methods: {
    async selectTrip(index) {
      if (this.selectedIndex == index) {
        this.selectedIndex = -1;
      } else {
        this.selectedIndex = index;
      }
    },
    async selectOption(item) {
      console.log(item);
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = { day: "numeric", month: "short", year: "numeric" };
      return new Intl.DateTimeFormat("en-NL", options).format(date);
    },
    goBack() {
      router.back();
    },
    userClicked(user) {
      if (!this.isLoggedInUser(user)) router.push("/profile/" + user._id);
    },
    isLoggedInUser(user) {
      return user._id === JSON.parse(localStorage.getItem("loggedInUser"))._id;
    },
    locationClicked(location) {
      this.location = location;
      this.showLocationModal = true;
    },
    addLocationClicked() {
      this.showLocationModal = true;
    },
    async handleLocationSave(newLocation) {
      const index = this.trip.locations.findIndex(
        (location) => location._id === newLocation._id
      );
      if (index !== -1) {
        this.trip.locations[index] = newLocation;
      } else {
        this.trip.locations.push(newLocation);
      }
      await TripsRepository.saveTrip(this.trip);
      this.closeLocationModal();
    },
    closeLocationModal() {
      this.location = undefined;
      this.showLocationModal = false;
    },
    async deleteClicked() {
      const confirmed = window.confirm(this.$i18n.t("confirm-delete-trip"));
      if (confirmed) {
        try {
          await TripsRepository.deleteTrip(this.trip);
          router.push("/home");
        } catch (error) {
          alert(error);
        }
      }
    },
    editClicked() {
      this.showTripModal = true;
    },
    closeTripModal() {
      this.showTripModal = false;
    },
    async handleTripSave(trip) {
      await TripsRepository.saveTrip(trip);
      this.trip = trip;
      this.closeTripModal();
    },
    async deleteLocationClicked(location) {
      const confirmed = window.confirm(
        this.$i18n.t("confirm-delete-location") + " " + location.name
      );
      if (confirmed) {
        try {
          const index = this.trip.locations.findIndex(
            (loc) => loc._id === location._id
          );
          if (index !== -1) {
            this.trip.locations.splice(index, 1);
          }
          await TripsRepository.saveTrip(this.trip);
        } catch (error) {
          alert(error);
        }
      }
    },
    async deleteUserClicked(user) {
      const confirmed = window.confirm(
        this.$i18n.t("confirm-delete-user") + " " + user.displayName
      );
      if (confirmed) {
        try {
          const index = this.trip.users.findIndex(
            (loc) => loc._id === user._id
          );
          if (index !== -1) {
            this.trip.users.splice(index, 1);
          }
          await TripsRepository.saveTrip(this.trip);
        } catch (error) {
          alert(error);
        }
      }
    },
    isUserOwner(user) {
      const index = this.trip.owners.findIndex((loc) => loc._id === user._id);
      return index !== -1;
    },
    async demoteUserClicked(user) {
      const confirmed = window.confirm(
        this.$i18n.t("confirm-demote-user") + " " + user.displayName
      );
      if (confirmed) {
        try {
          const index = this.trip.owners.findIndex(
            (loc) => loc._id === user._id
          );
          if (index !== -1) {
            this.trip.users.push(user);
            this.trip.owners.splice(index, 1);
          }
          await TripsRepository.saveTrip(this.trip);
        } catch (error) {
          alert(error);
        }
      }
    },
    async promoteUserClicked(user) {
      const confirmed = window.confirm(
        this.$i18n.t("confirm-promote-user") + " " + user.displayName
      );
      if (confirmed) {
        try {
          const index = this.trip.users.findIndex(
            (loc) => loc._id === user._id
          );
          if (index !== -1) {
            this.trip.owners.push(user);
            this.trip.users.splice(index, 1);
          }
          await TripsRepository.saveTrip(this.trip);
        } catch (error) {
          alert(error);
        }
      }
    }
  },
  async created() {
    if (this.$route.params.id) {
      this.trip = await TripsRepository.fetchTrip(this.$route.params.id);
    }
  }
};
</script>

<style scoped>
.details-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.navigation-bar {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  flex: 0;
  padding: 0.4em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.back-container {
  display: flex;
  align-items: center;
  gap: 0.5em;
  cursor: pointer;
  width: fit-content;
}
.arrow-icon {
  font-size: 2em;
}
.action-icons-container {
  display: flex;
  padding: 0.2em;
  gap: 1em;
  margin-right: 0.5em;
}
.delete-icon {
  color: var(--danger);
  cursor: pointer;
}
.edit-icon {
  cursor: pointer;
}
.location-actions-container {
  display: flex;
  padding: 0.2em;
  gap: 1em;
  margin-right: 0.5em;
}
.delete-location-icon {
  color: var(--danger);
  cursor: pointer;
}
.users-actions-container {
  display: flex;
  padding: 0.2em;
  gap: 1em;
  margin-right: 0.5em;
}
.delete-user-icon {
  color: var(--danger);
  cursor: pointer;
  display: flex;
  align-items: center;
}
.demote-user-icon {
  color: var(--danger);
  cursor: pointer;
}
.promote-user-icon {
  color: var(--primary);
  cursor: pointer;
}
.title {
  margin: 0;
  font-size: 1.3em;
}
.loader {
  margin: auto;
}
.section-title {
  padding-left: 2.5em;
  font-size: 1em;
  margin-bottom: 0.1em;
}
.items-container {
  background-color: var(--background-secondary);
  border-radius: 1em;
  margin-left: 0.5em;
  margin-right: 0.5em;
  overflow: hidden;
}
.item {
  padding-left: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.clickable:hover {
  cursor: pointer;
  background-color: var(--primary-lower);
}
.separator {
  border-bottom: solid 1px var(--secondary);
}
.item-title {
  flex: 1;
  font-size: 1.2em;
  padding-top: 0.7em;
  padding-bottom: 0.7em;
  margin: 0;
}
.item-title-container {
  flex: 1;
  display: flex;
  align-items: center;
}
.item-value {
  font-size: 1em;
  margin: 0;
  margin-right: 1em;
}
.button {
  color: var(--primary);
}
.disabled {
  background-color: var(--background-secondary);
  opacity: 0.5;
}
</style>
