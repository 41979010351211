<template>
  <div class="main-container">
    <TabBar v-if="!isLoading" :items="items" />
    <PulseLoader v-if="isLoading" size="1em" class="loader" />
    <transition name="fade" mode="out-in">
      <div class="details-container" v-if="!isLoading">
        <Home v-if="$route.path.startsWith('/home')" />
        <Details
          v-if="$route.path.startsWith('/details/') && $route.params.id"
        />
        <UserProfile
          v-if="$route.path.startsWith('/profile/') && $route.params.id"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import TabBar from "./components/TabBar/TabBar.vue";
import router from "@/router";
import Home from "@/views/Home.vue";
import Details from "./Details.vue";
import UserProfile from "./UserProfile.vue";

export default {
  name: "MainScreen",
  components: { TabBar, Home, Details, UserProfile },
  data() {
    return {
      items: [
        {
          title: this.$i18n.t("home-tab"),
          path: "/home",
          onClick: () => {
            router.push("/home");
          }
        },
        {
          title: this.$i18n.t("profile-tab"),
          path: "/myprofile",
          onClick: () => {
            router.push("/profile");
          }
        },
        {
          title: this.$i18n.t("settings-tab"),
          path: "/settings",
          onClick: () => {
            router.push("/settings");
          }
        },
        {
          title: this.$i18n.t("logout"),
          onClick: () => {
            const confirmed = window.confirm(this.$i18n.t("confirm-logout"));
            if (confirmed) {
              localStorage.removeItem("loggedInUser");
              router.push("/login");
            }
          }
        }
      ]
    };
  },
  computed: {
    isLoading() {
      return (
        localStorage.getItem("loggedInUser") === null ||
        localStorage.getItem("loggedInUser") === undefined
      );
    }
  },
  async created() {
    if (
      localStorage.getItem("loggedInUser") === null ||
      localStorage.getItem("loggedInUser") === undefined
    ) {
      router.push("/login");
    }
  }
};
</script>

<style scoped>
.loader {
  margin: auto;
}
.main-container {
  height: 100dvh;
  display: flex;
}
.details-container {
  flex: 1;
  display: flex;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-in;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
