<template>
  <div class="modal-background" v-if="isVisible" @click="closeModal()">
    <div class="modal-container" @click.stop>
      <form @submit.prevent="saveChanges">
        <div class="modal-header">
          <p @click="closeModal" class="modal-button">Cancel</p>
          <h2>{{ $i18n.t("trip") }}</h2>
          <button type="submit" class="modal-button save-button">Save</button>
        </div>
        <div class="modal-content">
          <div class="form-group">
            <label>{{ $i18n.t("title") }}</label>
            <input
              type="text"
              v-model="trip.name"
              required
              @input="fetchLocationSuggestions"
            />
          </div>
          <div class="form-group">
            <Datepicker
              required
              v-model="range"
              range
              lang="en"
              circle
              showPickerInital
            />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import VueDatepickerUi from "vue-datepicker-ui";
import "vue-datepicker-ui/lib/vuedatepickerui.css";
export default {
  name: "TripModal",
  components: { Datepicker: VueDatepickerUi },
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    initialTrip: {
      type: Object,
      default: undefined,
      required: false
    }
  },
  data() {
    return {
      trip: { ...this.initialTrip },
      range: [this.initialTrip?.startDate, this.initialTrip?.endDate]
    };
  },
  created() {},
  methods: {
    closeModal() {
      const confirmed = window.confirm(
        this.$i18n.t("confirm-discard-trip-changes")
      );
      if (confirmed) {
        this.$emit("close");
      }
    },
    saveChanges() {
      if (this.trip.name && this.range.length == 2) {
        this.trip.startDate = this.range[0];
        this.trip.endDate = this.range[1];
        this.$emit("save", this.trip);
      } else {
        alert(this.$i18n.t("please-fill-out-the-fields"));
      }
    }
  }
};
</script>

<style scoped>
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  overflow: auto;
  z-index: 1000;
}

.modal-container {
  background-color: var(--background-secondary);
  border-radius: 10px;
  width: 90%;
  max-width: 90em;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  margin: auto;
  margin-top: auto;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2em;
  padding-left: 1em;
  padding-right: 1em;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}
.modal-content {
  padding-left: 2em;
  padding-right: 2em;
}

.modal-button {
  background: none;
  border: none;
  font-size: 1em;
  cursor: pointer;
}

.save-button {
  color: var(--primary);
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}
.form-group input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  font-size: 14px;
}
</style>
