<template>
  <router-view />
</template>

<script>
export default {
  name: "App"
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  height: 100dvh;
  width: 100vw;
}
body {
  margin: 0;
  background-color: #ffffff;
  color: #222222;
}

.dark-theme {
  margin: 0;
  background-color: #1e1e1e;
  color: #eeeeee;
}

@font-face {
  font-family: "BukhariScript";
  src: url("@/assets/BukhariScript.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #1e1e1e;
    color: #eeeeee;
  }
  textarea,
  input {
    background-color: rgb(90, 90, 90);
    color: rgb(200, 200, 200);
  }
  :root {
    --primary: rgb(86, 188, 108);
    --danger: rgb(230, 0, 0);
    --primary-lower: rgb(86, 188, 108, 0.2);
    --secondary: rgb(100, 100, 100);
    --background-secondary: rgb(50, 50, 50);
  }
}

@media (prefers-color-scheme: light) {
  body {
    background-color: #eeeeee;
    color: #1e1e1e;
  }
  textarea,
  input {
    background-color: rgb(170, 170, 170);
    color: rgb(50, 50, 50);
  }
  :root {
    --primary: rgb(86, 188, 108);
    --danger: rgb(230, 0, 0);
    --primary-lower: rgb(86, 188, 108, 0.2);
    --secondary: rgb(100, 100, 100);
    --background-secondary: rgb(200, 200, 200);
  }
}
</style>
